
@import "../../../assets/scss/variables.scss";

.tag {
    padding: 8px 10px;
    border-radius: 5px;
    font-size: $font-xs;
    &.dark {
        background: rgba($color: $white, $alpha: 0.2);
        color: $white;
    }
    &.light {
        background: rgba($color: $primary, $alpha: 0.2);
        color: $dark;
    }
}