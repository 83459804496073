@import '../../assets/scss/variables.scss';
@import '../../assets/scss/breakpoints.scss';

.career {
    .openPosition {
        background: $grayLight;
        .openingCard {
            background: $white;
            padding: 40px 50px 25px;
            border-radius: 30px;
            box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.05);
            transition: all 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
            @include sm {
                border-radius: 15px;
                padding: 20px 20px 10px;
            }
            &:hover {
                transform: perspective(1500px) rotateX(-7deg) rotateY(19deg) scale3d(1.05, 1.05, 1.05);
            }
        }
    }
    .joinTeam {
        a {
            color: $dark;
        }
    }
}