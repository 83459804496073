// Color
$primary: #ffca10;
$primaryLight: #FFFCE0;
$secondary:#232038;
$white:#ffffff;
$dark:#323232;
$gray:#8E8E8E;
$grayLight:#F4F4F4;
// $red:#FF5325;
$red:#8E8E8E;
$green:#00b100;

// Border
$border :  1px solid #e4e4e4;

// Font Sizes
$font-xxs:10px;
$font-xs:12px;
$font-sm:14px;
$font-md:15px;
$font-lg:16px;
$font-xl:18px;
$font-xxl:20px;

// BreackPoints
$screen-xxsm:320px;
$screen-msm:370px;
$screen-xsm:380px;
$screen-mo:540px;
$screen-sm:767px;
$screen-iPad:770px;
$screen-laptop:900px;
$screen-tab:992px;
$screen-md:1024px;
$screen-smd:1200px;
$screen-xmd:1300px;
$screen-xxmd: 1366px;
$screen-xlmd:1440px;
$screen-lg:1510px;
$screen-xlg:1600px;
$screen-xxlg:1920px;
$screen-biglg:1921px;
$screen-heightMd:1060px;
$screen-heightLg:1200px;