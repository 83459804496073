@import '../../assets/scss/variables.scss';
@import '../../assets/scss/breakpoints.scss';

.aboutPage {
    .videoSection {
        .video {
            position: relative;
            .play {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                cursor: pointer;
            }
            video {
                border-radius: 30px;
               &[poster]{
                    object-fit: cover;
                }
            }
            .overview {
                width: calc(100% - 100px);
                margin: 0 auto;
                background: $white;
                padding: 40px;
                border-radius: 30px;
                position: absolute;
                bottom: -60px;
                left: 0;
                right: 0;
                border: $border;
                box-shadow: 0px 2px 25px 5px rgba(0, 0, 0, 0.05);
                @include mo {
                    bottom: -200px;
                }
                > div {
                    @include tab {
                        grid-gap: 30px 0;
                    }
                }
                .overviewBox {
                    position: relative;
                    span {
                        white-space: nowrap;
                    }
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                    &::after {
                        content: '';
                        width: 20px;
                        height: 20px;
                        border-radius: 20px;
                        background: $primary;
                        position: absolute;
                        top: 40%;
                        right: -32%;
                        @include tab {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .logos {
        display: flex;
        grid-gap: 60px;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        padding: 120px 30px;
        margin-top: 60px;
        @include sm {
            padding: 70px 30px;
        }
        @include mo {
            margin-top: 200px;
        }
        img {
            max-width: 120px;
            height: 100%;
        }
    }
}