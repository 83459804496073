@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/breakpoints.scss";

.serviceEnquiry {
    background: $white;
    border: $border;
    padding: 30px;
    border-radius: 30px;
    box-shadow: 0px 20px 25px 3px rgba(0, 0, 0, 0.07);
    height: 100%;
    button {
        padding-bottom: 0 !important;
    }
}