@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/breakpoints.scss';

.navigation {
    position: sticky !important;
    top: 0;
    background: $white;
    z-index: 99;

    .logo {
        cursor: pointer;
        margin-left: 50px;
        @include tab {
            margin-left: 20px;        
        }
    }
}
.navContainer {
    min-height: 75px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.05);
}
.menus {
    a {
        color: $gray;
        font-family: 'Poppins-Regular', sans-serif;
        font-weight: normal;
        padding: 25px 25px 0 !important;
        display: flex;
        align-items: center;
        position: relative;
        @include smd {
            padding: 25px 15px 0 !important;
        }
        @include sm {
            margin-top: 20px;
        }
        &.active {
            color: $secondary;
            font-family: 'Poppins-Medium', sans-serif;
            img {
                transform: scale(1);
            }
        }
        &:hover {
            img {
                transform: scale(1);
            }
        }
        img {
            position: absolute;
            height: 35px;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: -15px;
            transform: scale(0);
            transition: all .3s ease-in-out;
            @include sm {
                margin: 0 0 0 20px;
            }
        }
    }
}
.boost {
    width: 44px;
}
.certified {
    width: 185px;
    margin-left: 20px;
    @include mo {
        width: 65%;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}
.eBtn {
    background: $primary;
    border-radius: 0;
    border: none;
    color: $dark;
    padding: 25px 45px;
    font-family: 'Poppins-SemiBold', sans-serif;
    @include tab {
        width: 100%;
    }
}
.responsiveMenu {
    .serviceMenu {
        @include sm {
            position: relative;
        }
        &:hover {
            &::before {
                transform: scale(1);
            }
        }
        &::before {
            content: '';
            position: absolute;
            background: url(../../../../public/images/service.png) no-repeat;
            background-size: auto 35px;
            width: 35px;
            height: 35px;
            margin-left: 52px;
            top: 5px;
            transform: scale(0);
            transition: all .3s ease-in-out;
            @include sm {
                margin-left: 15px;
            }
        }
        &.active {
            &::before {
                transform: scale(1);
            }
        }
        > a {
            font-size: $font-lg !important;
        }
        a {
            font-size: $font-sm;
            white-space: normal;
        }
        .serviceSubMenu {
            a {
                padding: 8px !important;
                margin-top: 0;
                padding-top: 15px !important;
            }
        }
    }
    .active {
        @include sm {
            position: relative;
        }
        > a {
            color: $secondary;
            font-family: 'Poppins-Medium', sans-serif;
        }
    }
    .menus {
        @include tab {
            align-items: start !important;
            height: calc(100vh - 120px);
            justify-content: start !important;
        }
    }
    .contactDetails {
        display: none;
        margin-top: auto;
        position: sticky;
        bottom: 1rem;
        background: $primaryLight;
        a {
            color: $dark;
            font-size: 20px;
            line-height: 1;
            margin-top: 0;
            img {
                position: initial;
                transform: scale(1);
                height: 24px;
                margin: 0 15px 0 0;
            }
            // &.phone {
            //     font-size: 26px;
            //     font-family: 'Poppins-Bold', sans-serif;
            // }
        }
        @include tab {
            display: block;
        }
    }
}