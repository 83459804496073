@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';
@import '../../../assets/scss/breakpoints.scss';


.xs {
    font-size: 14px;
    line-height: 24px;
    @include textStyling;
}
.sm {
    font-size: 15px;
    line-height: 26px;
    @include textStyling;
}
.md {
    font-size: 16px;
    line-height: 28px;
    @include textStyling;
}
.lg {
    font-size: 18px;
    line-height: 30px;
    @include textStyling;
}
.xl {
    font-size: 20px;
    line-height: 32px;
    @include textStyling;
}
.xxl {
    font-size: 22px;
    line-height: 36px;
    @include textStyling;
    @include sm {
        font-size: 16px;
        line-height: 28px;
    }
}