@font-face {
  font-family: 'upspot';
  src:  url('../fonts/Upspot/upspot.eot?sstf27');
  src:  url('../fonts/Upspot/upspot.eot?sstf27#iefix') format('embedded-opentype'),
    url('../fonts/Upspot/upspot.ttf?sstf27') format('truetype'),
    url('../fonts/Upspot/upspot.woff?sstf27') format('woff'),
    url('../fonts/Upspot/upspot.svg?sstf27#upspot') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'upspot' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Right-arrow:before {
  content: "\e915";
}
.icon-Social-media:before {
  content: "\e918";
}
.icon-List:before {
  content: "\e917";
}
.icon-Hastag:before {
  content: "\e91a";
}
.icon-Post:before {
  content: "\e91c";
}
.icon-Influencers:before {
  content: "\e91d";
}
.icon-Growth:before {
  content: "\e91e";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-up:before {
  content: "\e901";
}
.icon-BuildingOffice:before {
  content: "\e902";
}
.icon-Calling:before {
  content: "\e903";
}
.icon-check:before {
  content: "\e904";
}
.icon-chevron-down:before {
  content: "\e905";
}
.icon-chevron-left:before {
  content: "\e906";
}
.icon-chevron-right:before {
  content: "\e907";
}
.icon-chevron-up:before {
  content: "\e908";
}
.icon-Close:before {
  content: "\e909";
}
.icon-CodeBracket:before {
  content: "\e90a";
}
.icon-Edit:before {
  content: "\e90b";
}
.icon-facebook:before {
  content: "\e90c";
}
.icon-Graphics:before {
  content: "\e90d";
}
.icon-instagram:before {
  content: "\e90e";
}
.icon-linkedin:before {
  content: "\e90f";
}
.icon-Marketing:before {
  content: "\e910";
}
.icon-Menu:before {
  content: "\e911";
}
.icon-Play:before {
  content: "\e912";
}
.icon-Printer:before {
  content: "\e913";
}
.icon-Product:before {
  content: "\e914";
}
.icon-Seo:before {
  content: "\e916";
}
.icon-Twitter:before {
  content: "\e91b";
}
