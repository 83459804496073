@import '../../assets/scss/variables.scss';
@import '../../assets/scss/breakpoints.scss';

.portfolio {
    .gallery {
        ul {
            @include sm {
                border: none;
                overflow: auto;
                flex-wrap: nowrap;
                height: 60px;
            }
            &::-webkit-scrollbar {
                height: 8px;
                border-radius: 8px;
            }
            &::-webkit-scrollbar-track {
                background: $grayLight;
                border-radius: 8px;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba($color: $gray, $alpha: 0.3);
                border-radius: 8px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: $gray;
            }
            li {
                margin: 0 10px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        .grid {
            display: grid;
            grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
            justify-content: center;
            grid-gap: 30px;
            flex-wrap: wrap;
            .gridBox {
                position: relative;
                background: $white;
                border-radius: 15px;
                padding: 15px;
                border: $border;
                box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.05);
                overflow: hidden;

                img {
                    border-radius: 10px;
                    margin-bottom: 20px;
                    max-height: 205px;
                    object-fit: cover;
                }

                h4 {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    transition: all 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
                }
                
                button {
                    position: absolute;
                    bottom: -70px;
                    transition: all 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
                }

                &:hover {
                    h4 {
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
                    }
                    button {
                        bottom: 0;
                        transition: all 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
                    }
                }
            }
        }
    }
}