@import '../../assets/scss/variables.scss';
@import '../../assets/scss/breakpoints.scss';

.contact {
    .formSection {
        // background: $grayLight;
        .form {
            label {
                display: block;
                color: $dark;
                font-size: $font-sm;
                margin-bottom: 7px;
            }
            input, textarea {
                width: 100%;
                min-height: 50px;
                padding: 10px 20px;
                border-radius: 10px;
                border: $border;
                background: $white;
            }         
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            button {
                width: 150px;
            }   
        }
        .contactDetails {
            text-align: center;
            margin-top: 5rem;
            span, a {
                font-size: 24px;
                color: $gray;
                text-decoration: none;
            }
            a {
                margin: 0 15px;
                &:hover {
                    color: $dark;
                }
            }
            @include sm {
                span {
                    .sep {
                        display: none;
                    }
                    a {
                        display: block;
                        width: 100%;
                        margin: 15px 0;
                    }
                }
            }
        }
    }
}