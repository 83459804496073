@import '../../assets/scss/variables.scss';
@import '../../assets/scss/breakpoints.scss';

.blogPage {
    .videoSection {
        .video {
            position: relative;
            .videoContainer {
                position: absolute;
                width: 100%;
                height: 80%;
                z-index: 9;
                @include sm {
                    height: 52%;
                }
            }
            .play {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 66px;
                margin: auto;
                cursor: pointer;
                transform: translateY(-50px);
                // filter: drop-shadow(0 0 20px #bfa100);
                animation: pulse 2s infinite;
                @keyframes pulse {
                    0% {
                        transform: scale(1) rotate(0deg);
                    }
                    50% {
                        transform: scale(1.1) rotate(45deg);
                    }
                    100% {
                        transform: scale(1) rotate(0deg);
                    }
                }     
                @include sm {
                    top: -200px;
                    width: 40px;
                }           
            }
            video {
                border-radius: 30px;
               &[poster]{
                    object-fit: cover;
                }
            }
            .overview {
                width: calc(100% - 100px);
                margin: 0 auto;
                background: rgba($color: $white, $alpha: 0.5);
                backdrop-filter: blur(10px);
                padding: 40px;
                border-radius: 30px;
                margin-top: -40px;
                position: relative;
                z-index: 9;
                // position: absolute;
                // bottom: -60px;
                // left: 0;
                // right: 0;
                border: $border;
                box-shadow: 0px 2px 25px 5px rgba(0, 0, 0, 0.05);
                @include mo {
                    width: 100%;
                    padding: 20px;
                    position: initial;
                    margin-top: 20px;
                }
                > div {
                    @include tab {
                        grid-gap: 30px 0;
                    }
                }
                .overviewBox {
                    position: relative;
                    span {
                        white-space: nowrap;
                    }
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                    &::after {
                        content: '';
                        width: 20px;
                        height: 20px;
                        border-radius: 20px;
                        background: $primary;
                        position: absolute;
                        top: 40%;
                        right: -32%;
                        @include tab {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .logos {
        margin-top: 40px;
        @include sm {
            margin-top: 20px;
        }
        .logoGrid {
            display: flex;
            grid-gap: 60px;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            padding: 0 30px 40px;
            margin-top: 40px;

            @include sm {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                padding: 0 20px;
                grid-gap: 20px;
                margin: 20px 0;
            }
            img {
                max-width: 120px;
                height: 100%;
                @include sm {
                    height: auto;
                    width: 70px;
                    margin: 0 auto;
                }
            }
        }
    }
    .aboutUsMobile {
        .readMore {
            font-weight: 600;
        }
    }
}