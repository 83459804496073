@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/breakpoints.scss";

.servicesCard {
    border: $border;
    border-top: none;
    border-left: none;
    width: 100%;
    min-height: 375px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        border-top: $border;
    }
    &:nth-child(1), &:nth-child(6) {
        border-left: $border;
    }
    @include mo {
        min-height: 330px;
    }
    .mainIcon {
        position: absolute;
        font-size: 45px;
        top: 40px;
        right: 40px;
        color: $gray;
        z-index: 2;
        transition: all 0.6s ease;
        transition-timing-function: 0.6s cubic-bezier(.17,.67,.73,.28);
        @include mo {
            font-size: 25px;
            top: 25px;
            right: 25px;
        }
    }
    .name {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 50px 30px;
        @include mo {
            padding: 30px 10px;
        }
        i {
            font-size: 28px;
            margin-left: 8px;
        }
    }
    .serviceList {
        background: $primaryLight;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 50px 30px 0;
        display: flex;
        flex-direction: column;
        top: 100%;
        transition: all 0.6s ease;
        transition-timing-function: 0.6s cubic-bezier(.17,.67,.73,.28);
        @include xmd {
            padding: 50px 20px 0;
        }
        @include mo {
            padding: 50px 10px 0;
            h3 {
                font-size: 16px;
                line-height: 20px;
            }
            ul {
                li {
                    font-size: 13px !important;
                    margin-bottom: 5px !important;
                    line-height: 20px !important;
                    i {
                        font-size: 15px !important;
                    }
                }
            }
        }
        ul {
            list-style: none;
            padding-left: 0;
            li {
                font-size: $font-lg;
                color: $gray;
                line-height: 25px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                font-family: 'Poppins-Regular', sans-serif;
                @include xmd {
                    font-size: 14px;
                }
                i {
                    font-size: 24px;
                    color: $red;
                    margin-right: 8px;
                }
            }
        }
        button {
            margin: auto -30px 0;
            background: $primary;
            border-radius: 0;
            font-size: 18px;
        }
    }
    &:hover {
        .serviceList {
            top: 0;
            transition: all 0.6s ease;
            transition-timing-function: 0.6s cubic-bezier(.17,.67,.73,.28);
        }
        .mainIcon {
            top: 11px;
            right: 11px;
            font-size: 35px;
            transition: all 0.6s ease;
            transition-timing-function: 0.6s cubic-bezier(.17,.67,.73,.28);
            @include mo {
                font-size: 20px;
                top: 20px;
                right: 20px;
            }
        }
    }
}